import st from './question.module.scss';
import React from 'react';
import {Button} from '../../../components/button/button';
import {URLS} from '../../../components/nav/nav';

export const Question = () => (
  <div className={st.section}>
    <div className={st.title}>
      Interested In Expanding <br />
      <strong>Your Knowledge?</strong>
    </div>
    <Button className='primary' to={URLS.BLOG}>
      Visit our blog
    </Button>
  </div>
);
