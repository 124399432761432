import {useStaticQuery, graphql} from 'gatsby';
import React from 'react';
import {mark} from '../../../utils/marked';
import st from './tech-at-service.module.scss';

export const TechAtService = () => {
  const {
    restApiForClientsPage: {
      technology_at_service: {title, subtitle, image},
    },
  } = query();

  return (
    <div className={st.section} style={{backgroundImage: `url(${image.url})`}}>
      <div className={st.section__wrapper}>
        <div className={st.content}>
          <div className={st.title} dangerouslySetInnerHTML={mark(title)}></div>
          <div className={st.subtitle} dangerouslySetInnerHTML={mark(subtitle)}></div>
        </div>
        <div className={st.image__wrapper}>
          <div className={st.image}></div>
        </div>
      </div>
    </div>
  );
};

const query = () =>
  useStaticQuery(graphql`
    query {
      restApiForClientsPage {
        technology_at_service {
          title
          subtitle
          image {
            url
            name
          }
        }
      }
    }
  `);
