import React from 'react';
import {Layout} from '../components/layout/layout';
import {Clients} from '../pages-components/clients/clients';

const ClientsPage = () => (
  <Layout>
    <Clients />
  </Layout>
);

export default ClientsPage;
