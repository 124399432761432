import React from 'react';
import {Button} from '../../../components/button/button';
import st from './projects.module.scss';
import {URLS} from '../../../components/nav/nav';
export const Projects = () => {
  return (
    <div className={st.section}>
      <h2 className={st.title}>
        Projects <strong>We Delivered</strong>
      </h2>
      <p className={st.subtitle}>
        <h3>Our professionals worked on projects requiring experience and high quality.</h3> The diversity of IT Experts
        in our Community allows us to implement even the most demanding projects. We are glad that we can boast about
        the work of our talented specialists and cooperation with companies that aim high.
      </p>
      <div className={st.cards}>
        <div className={st.card}>
          <h4 className={st.card__title}>Outsourced iOS Developers</h4>
          <div className={st.content__wrapper}>
            <div className={st.card__text}>
              <p className={st.card__subtitle}>
                We quickly extended the delivery team with additional talent and took over the iOS area of the project’s
                scope. The ambitious project was to deliver several different banking systems and custom applications
                for a Polish Cooperative Banking Group <b>(28 banks)</b>.
              </p>
            </div>
            <div className={st.card__image}>
              <img src={'/project_1.svg'} alt={`Outsourced iOS Specialists`} />
            </div>
          </div>
        </div>
        <div className={st.card}>
          <h4 className={st.card__title}>Contributed in Building AI Data Platform</h4>
          <div className={st.content__wrapper}>
            <div className={st.card__text}>
              <p className={st.card__subtitle}>
                The platform was built for one of the largest marketing agencies - <b>The Data Strategy</b>. It was
                integrated with the innovative and powerful AI&data crawling engine to provide valuable insights
                Marketing Directors in the form of well-organized dashboards.
              </p>
            </div>
            <div className={st.card__image}>
              <img src={'/project_2.svg'} alt={`Contributed in Building AI Data Platform`} />
            </div>
          </div>
        </div>
        <div className={st.card}>
          <h4 className={st.card__title}>Created a Software for a Cyber Security Platform</h4>
          <div className={st.content__wrapper}>
            <div className={st.card__text}>
              <p className={st.card__subtitle}>
                Our specialists played a key role in the project by creating the solution’s detailed architecture,
                setting up effective infrastructure and DevOps strategy, and delivering the backend, frontend,
                machine-learning and security elements of the platform.
              </p>
              <a
                className={st.link}
                href={'https://drive.google.com/file/d/1saqkuTUGE7bYyp8lr53cYuXgliYuJWjx/view?usp=sharing'}
                rel='noreferrer'
                target='_blank'
              >
                See a Case Study
              </a>
            </div>
            <div className={st.card__image}>
              <img src={'/project_3.svg'} alt={`Delivered a Tailored Made Application`} />
            </div>
          </div>
        </div>
        <div className={st.card}>
          <h4 className={st.card__title}>Designed and Built Client-Facing Web Apps</h4>
          <div className={st.content__wrapper}>
            <div className={st.card__text}>
              <p className={st.card__subtitle}>
                We were responsible for designing and building client-facing web apps, websites, and customer portals
                for one of the largest international household goods prodivers. Moreover, it is long-term cooperation.
              </p>
              <a
                className={st.link}
                href={'https://drive.google.com/file/d/1ngJUQm4kuNgu9vasykuCnM793ylXms1A/view?usp=sharing'}
                rel='noreferrer'
                target='_blank'
              >
                See a Case Study
              </a>
            </div>
            <div className={st.card__image}>
              <img src={'/project_4.svg'} alt={`Designed and Built Client-Facing Web Apps`} />
            </div>
          </div>
        </div>
        <div className={st.card}>
          <h4 className={st.card__title}>Built the component for Premium Car Manufacturer</h4>
          <div className={st.content__wrapper}>
            <div className={st.card__text}>
              <p className={st.card__subtitle}>
                The client was looking for angular Experts to help with perform ance issues by creating a component that
                is compatible with Angular’s template and reactive forms.
              </p>
            </div>
            <div className={st.card__image}>
              <img
                src={'/project_5.svg'}
                alt={`Built the component for Premium Car
Manufacturer`}
              />
            </div>
          </div>
        </div>
        <div className={st.card}>
          <h4 className={st.card__title}>Created a Proof of Concept in Just 14 Days</h4>
          <div className={st.content__wrapper}>
            <div className={st.card__text}>
              <p className={st.card__subtitle}>
                Our Client needed to quickly expand its team of specialists with an experienced Frontend Developer. It
                took us only 1 day to provide an Expert. The project consisted of creating a digital system for the city
                which processes related data.
              </p>
              <a
                className={st.link}
                href={'https://drive.google.com/file/d/1vfRnu6ImNLjTYSI7IZHsSkf1nHiVsw4z/view?usp=sharing'}
                rel='noreferrer'
                target='_blank'
              >
                See a Case Study
              </a>
            </div>
            <div className={st.card__image}>
              <img
                src={'/project_6.svg'}
                alt={`Covered the
whole scope of the development project`}
              />
            </div>
          </div>
        </div>
      </div>
      <Button to={URLS.BUILD} stClassName={st.button}>
        Start a cooperation
      </Button>
    </div>
  );
};
