import {useStaticQuery, graphql} from 'gatsby';
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import st from './coworkers-carousel.module.scss';

const responsive = {
  desktop: {
    breakpoint: {max: 3000, min: 1200},
    items: 2,
  },
  mobile: {
    breakpoint: {max: 1199, min: 0},
    items: 1,
  },
};

const CustomRightArrow = props => {
  return <button {...props} className={st.button__right} />;
};

const CustomLeftArrow = props => {
  return <button {...props} className={st.button__left} />;
};
export const CoworkersCarousel = () => {
  const {
    restApiHomePage: {
      coworkers_section: {Coworkers_coworkers = []},
    },
  } = query();

  return (
    <Carousel
      responsive={responsive}
      ssr={true}
      infinite={true}
      removeArrowOnDeviceType={['mobile']}
      className={st.carousel}
      customRightArrow={<CustomRightArrow />}
      customLeftArrow={<CustomLeftArrow />}
      autoPlay={true}
      autoPlaySpeed={5000}
    >
      {Coworkers_coworkers.map((coworkerData, index) => (
        <CoworkerCard key={index} coworkerData={coworkerData} />
      ))}
    </Carousel>
  );
};

const CoworkerCard = ({coworkerData}) => {
  const {Avatar, Description, IsCurrentlyAt, Logo, Name, Role} = coworkerData;

  return (
    <div className={st.card}>
      <div className={st.card__wrapper}>
        <img className={st.card__avatar} src={Avatar?.url} alt={Avatar?.name} />
        <div className={st.card__text}>
          <div className={st.card__name}>{Name}</div>
          <p className={st.card__role}>{Role}</p>
          <p className={st.card__description}>{Description}</p>
        </div>
        <div className={st.card__where}>
          <p className={st.card__currently}>{IsCurrentlyAt ? 'Currently at:' : 'Previously at:'}</p>
          <img className={st.card__logo} src={Logo?.url} alt={Logo?.name} />
        </div>
      </div>
    </div>
  );
};
const query = () =>
  useStaticQuery(graphql`
    query {
      restApiHomePage {
        coworkers_section {
          Coworkers_coworkers {
            Avatar {
              url
              name
            }
            Description
            IsCurrentlyAt
            Logo {
              url
              name
            }
            Name
            Opinion
            Role
            id
          }
        }
      }
    }
  `);
