import {graphql, useStaticQuery} from 'gatsby';
import React from 'react';
import {Button} from '../../../components/button/button';
import {mark} from '../../../utils/marked';
import st from './top-talents.module.scss';

export const TopTalents = () => {
  const {
    restApiForClientsPage: {
      Banner_section: {Background_image, Description, Title, button_one, button_two},
    },
  } = query();
  return (
    <>
      <div className={st.wrapper} style={{backgroundImage: `url(${Background_image.url})`}}>
        <div className={st.section}>
          <div className={st.title} dangerouslySetInnerHTML={mark(Title)}></div>
          <div className={st.subtitle} dangerouslySetInnerHTML={mark(Description)}></div>
          <div className={st.buttons__wrapper}>
            <Button to={button_one.url}>{button_one.text}</Button>
            <Button to={button_two.url} className={'secondary'}>
              {button_two.text}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

const query = () =>
  useStaticQuery(graphql`
    query {
      restApiForClientsPage {
        Banner_section {
          Background_image {
            url
            name
          }
          Description
          Title
          button_one {
            text
            url
          }
          button_two {
            text
            url
          }
        }
      }
    }
  `);
