import {graphql, useStaticQuery} from 'gatsby';
import React from 'react';
import {mark} from '../../../utils/marked';
import {CoworkersCarousel} from './coworkers-carousel';

import st from './coworkers.module.scss';

export const Coworkers = () => {
  const {
    restApiHomePage: {
      coworkers_section: {Coworkers_description, Coworkers_header, Coworkers_points, Coworkers_logos},
    },
  } = query();

  return (
    <div className={st.section}>
      <div className={st.section__content}>
        <div className={st.section__content__text}>
          <div className={st.title} dangerouslySetInnerHTML={mark(Coworkers_header)}></div>
          <div className={st.subtitle} dangerouslySetInnerHTML={mark(Coworkers_description)}></div>
          <div className={st.points} dangerouslySetInnerHTML={mark(Coworkers_points)}></div>
          {Coworkers_logos.map((logo: Logo) => (
            <img key={logo.url} src={logo.url} alt={logo.name} />
          ))}
        </div>
        <div className={st.section__content__carousel}>
          <CoworkersCarousel />
        </div>
      </div>
    </div>
  );
};

const query = () =>
  useStaticQuery(graphql`
    query {
      restApiHomePage {
        coworkers_section {
          Coworkers_description
          Coworkers_header
          Coworkers_points
          Coworkers_logos {
            url
            name
          }
        }
      }
    }
  `);

interface Logo {
  url: string;
  name: string;
}
