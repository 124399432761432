import {useStaticQuery, graphql} from 'gatsby';
import React, {useEffect, useState} from 'react';
import {mark} from '../../../utils/marked';
import st from './elite-forces.module.scss';

export const EliteForces = () => {
  const {
    restApiForClientsPage: {
      Opinions_section: {Description, Title, opinions = []},
    },
  } = query();
  let interval;

  const [opinionIndex, setOpinionIndex] = useState(0);

  useEffect(() => {
    setOpinionChangeInterval();

    return () => {
      clearInterval(interval);
    };
  }, [opinionIndex]);

  const setOpinionChangeInterval = () => {
    interval = window.setInterval(() => {
      if (opinionIndex < opinions.length - 1) {
        setOpinionIndex(opinionIndex + 1);
      } else {
        setOpinionIndex(0);
      }
    }, 5000);
  };

  return (
    <div className={st.section}>
      <div className={st.section__wrapper}>
        <div className={st.section__text}>
          <div className={st.title} dangerouslySetInnerHTML={mark(Title)}></div>
          <div className={st.subtitle} dangerouslySetInnerHTML={mark(Description)}></div>
        </div>
        <div className={st.comments}>
          <div className={st.comment__wrapper}>
            {opinions.map(({name, opinion}, index) => (
              <div key={index} className={`${st.comment} ${index === opinionIndex ? st.comment__active : ''}`}>
                <img src={'/quote.png'} alt='quote' />
                <p className={st.comment__opinion}>{opinion}</p>
                <p className={st.comment__name}>{name}</p>
              </div>
            ))}
          </div>
          <div className={st.comment_logos}>
            <div className={st.logos}>
              {opinions.map(({brand_logo}, index) => (
                <div key={index} className={st.logo}>
                  <div className={st.logo__wrapper}>
                    <img src={brand_logo.url} alt={brand_logo.name} />
                  </div>
                </div>
              ))}
            </div>

            <div className={`${st.logo__timer} ${st.logo__time__active}`}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const query = () =>
  useStaticQuery(graphql`
    query {
      restApiForClientsPage {
        Opinions_section {
          Description
          Title
          opinions {
            name
            opinion
            brand_logo {
              name
              url
            }
          }
        }
      }
    }
  `);
