import React from 'react';
import {Coworkers} from '../homepage/coworkers/coworkers';
import {Projects} from './projects/projects';
import {EliteForces} from './elite-forces/elite-forces';
import {HowWeDiffer} from './how-we-differ/how-we-differ';

import {TechAtService} from './tech-at-service/tech-at-service';
import {TopTalents} from './top-talents/top-talents';
import {WhatWeOffer} from './what-we-offer/what-we-offer';
import {Helmet} from 'react-helmet';
import {useStaticQuery, graphql} from 'gatsby';
import {Question} from './question/question';

export const Clients = () => {
  const {
    restApiForClientsPage: {seo},
  } = query();
  return (
    <>
      <Helmet meta={[{name: 'description', content: seo.description}]}>
        <title>{seo.title}</title>
      </Helmet>
      <TopTalents />
      <WhatWeOffer />
      <EliteForces />
      <HowWeDiffer />
      <Coworkers />
      {/* <Benefits /> */}
      <Projects />
      <TechAtService />
      <Question />
    </>
  );
};

const query = () =>
  useStaticQuery(graphql`
    query {
      restApiForClientsPage {
        seo {
          title
          description
        }
      }
    }
  `);
