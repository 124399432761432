import React from 'react';
import {Button} from '../../../components/button/button';
import st from './what-we-offer.module.scss';

import {URLS} from '../../../components/nav/nav';
export const WhatWeOffer = () => {
  return (
    <div className={st.section}>
      <h2 className={st.title}>
        What We <strong>Offer?</strong>
      </h2>
      <p className={st.subtitle}>
        <h3>We are flexible as to the model of cooperation.</h3> We take into account project requirements, the scope of
        work, your available in-house resources, and a preferable way of cooperation. On this basis, we offer the
        following services.
      </p>
      <div className={st.row}>
        <div className={st.row_content}>
          <div className={st.row_content_title}>Staff Augumentation</div>
          <div className={st.row_content_description}>
            Strengthen your internal team and boost project development by adding software developers who work and
            communicate effectively, and take ownership of the issues they are challenged with. We ensure our IT Experts
            are highly qualiﬁed.
          </div>

          <Button
            stClassName={st.row_content_button}
            to={'https://bluehouse.tech/blog/staff-augmentation-access-it-talent/'}
          >
            Read more
          </Button>
        </div>
        <div className={st.image}>
          <img src={'/offer_1.svg'} alt='Staff Augumentation' />
        </div>
      </div>
      <div className={st.row}>
        <div className={st.image}>
          <img src={'/offer_2.svg'} alt='Dedicated Team' />
        </div>
        <div className={st.row_content}>
          <div className={st.row_content_title}>Dedicated Team</div>
          <div className={st.row_content_description}>
            A Dedicated Team Service is a great approach to successfully deliver your IT project using an experienced
            team of IT Experts who act as an extension to your company. This approach promotes ﬂexibility by letting you
            scale your team up or down.
          </div>
          <Button
            stClassName={st.row_content_button}
            to={'https://bluehouse.tech/blog/when-is-dedicated-team-best-solution/'}
          >
            Read more
          </Button>
        </div>
      </div>
      <Button stClassName={st.button} to={URLS.BUILD}>
        Estimate a project
      </Button>
    </div>
  );
};
