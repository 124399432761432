import React from 'react';
import st from './how-we-differ.module.scss';
import community from './assets/community.svg';
import experience from './assets/experience.svg';
import proactivness from './assets/proactivness.svg';
import feedback from './assets/feedback.svg';
import ownership from './assets/ownership.svg';
import trust from './assets/trust.svg';
import speed from './assets/speed.svg';
import knowledge from './assets/knowledge.svg';
import flexibility from './assets/flexibility.svg';
import {URLS} from '../../../components/nav/nav';
import {Button} from '../../../components/button/button';

const values = [
  {
    title: 'IT Community',
    text: 'The foundation of Blue House is a Community composed of IT Experts with a passion for what they do.',
    img: community,
  },
  {
    title: 'Senior Experience',
    text:
      'We only cooperate with senior professionals so they bring value to your project development right away. any extra time learning.',
    img: experience,
  },
  {
    title: 'Proactiveness',
    text: 'Our developers take initiative in task management and move the project forward without supervision.',
    img: proactivness,
  },
  {
    title: 'Culture of feedback',
    text: 'All of us communicate effectively with other team members and openly share ideas and views.',
    img: feedback,
  },
  {
    title: 'Sense of Ownership',
    text:
      'Our IT Experts are committed to their projects and want to participate in the decision-making process of software development.',
    img: ownership,
  },
  {
    title: 'Trust',
    text:
      'We usually work with Experts that are vouched for by our Community members and referrals are the main source of new applicants.',
    img: trust,
  },
];

const benefits = [
  {
    title: 'Speed',
    text:
      'Instant access to a network of Experts shortens the time your HR department would need to onboard new people.',
    img: speed,
  },
  {
    title: 'Knowledge',
    text:
      'An extremely powerful knowledge base represented by our Community brings real value to the most challenging projects.',
    img: knowledge,
  },
  {
    title: 'Flexibility',
    text:
      'Scale your team up or down at any given moment of product development. Our contracts ensure maximum flexibility.',
    img: flexibility,
  },
];

export const HowWeDiffer = () => {
  return (
    <div className={st.section}>
      <div className={st.middle__container} />
      <div className={st.title}>What Makes Us</div>
      <div className={`${st.title} ${st.bold}`}>Stand Out?</div>
      <div className={st.cards}>
        {values.map((card, index) => (
          <div key={card.title} className={index & 1 ? st.card__right : st.card__left}>
            <img className={st.card__image} src={card.img} alt={card.title} />
            <div className={st.card__text}>
              <div className={st.card__title}>{card.title}</div>
              <div className={st.card__subtitle}>{card.text}</div>
            </div>
          </div>
        ))}
      </div>
      <div className={st.benefit__section}>
        <div className={st.title}>Benefit From</div>
        <div className={`${st.title} ${st.bold}`}>Our IT Community</div>
        <div className={st.benefit__header}>
          <div className={st.benefit__intro}>
            The talent shortage is one of the key barriers to achieving expected business outcomes.
          </div>
          <div className={st.benefit__subintro}>
            We solve this problem by delivering high-performing IT professionals as well as building dedicated teams.
            Blue House is a perfect IT staffing partner because we already have people you need in our Community.
          </div>
        </div>
        <div className={`${st.cards} ${st.cards__benefit}`}>
          {benefits.map((card, index) => (
            <div key={card.title} className={index & 1 ? st.card__right : st.card__left}>
              <img className={st.card__image} src={card.img} alt={card.title} />
              <div className={st.card__text}>
                <div className={st.card__title}>{card.title}</div>
                <div className={st.card__subtitle}>{card.text}</div>
              </div>
            </div>
          ))}
        </div>
        <Button to={URLS.COMMUNITY} stClassName={st.button}>
          Read about Community
        </Button>
      </div>
    </div>
  );
};
